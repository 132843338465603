import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/Rooms.css";
import room1 from "../images/room-1.jpg"
import room2 from "../images/room-2.jpg"
import room3 from "../images/room-3.jpg"

const Rooms = () => {
  const [selectedRoom, setSelectedRoom] = useState("Small Single Room");
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

  const roomData = {
    "Small Single Room": {
      image:
        room3,
      description:
        "Perfect for solo travelers, this compact room offers comfort and convenience.",
      price: "2500 per night",
    },
    "Standard Single Room": {
      image:
        room2,
      description:
        "Our standard room comes with all essential amenities for a pleasant stay.",
      price: "3000 per night",
    },
    "Big Single Room": {
      image:
        room1,
      description:
        "Spacious and luxurious, ideal for guests looking for added comfort.",
      price: "5000 per night",
    },
  };

  const perks = [
    {
      icon: "🚗",
      label: "Free Parking",
      description:
        "We offer free parking for our guests to make sure you can easily park and relax without worrying about additional charges."
    },
    {
      icon: "📶",
      label: "Free Wi-Fi",
      description:
        "Stay connected with fast and reliable Wi-Fi throughout the hotel, available at no extra charge."
    },
    {
      icon: "🍳",
      label: "Breakfast (extra cost)",
      description:
        "Enjoy a delicious breakfast every morning to start your day off right. Breakfast is available at an additional cost."
    },
    {
      icon: "🔥",
      label: "Heaters (extra cost)",
      description:
        "Stay warm and cozy during your stay with our on request heaters, providing comfort no matter the weather outside."
    },
    {
      icon: "🛏️",
      label: "Extra Covers",
      description:
        "For those extra chilly nights, we provide additional covers on request in each room to ensure a restful, warm sleep."
    },
    {
      icon: "🧳",
      label: "Iron Box",
      description:
        "Keep your clothes crisp and wrinkle-free with an iron available upon request."
    },
    {
      icon: "🌿",
      label: "Fresh Air",
      description:
        "Experience the refreshing natural air from our location, surrounded by open spaces and greenery."
    },
    {
      icon: "🌞",
      label: "Natural Light",
      description:
        "Our rooms are designed with large windows to let in plenty of natural light, creating a bright and welcoming atmosphere."
    }
  ];
  

  const customerReviews = [
    { name: "Mary Wambo", review: "The small single room was cozy, but the cold mornings took some getting used to. The location is quiet and perfect for a quick getaway. Parking is free!" },
    { name: "Kipkirui Cheruiyot", review: "I stayed in the standard single room. The service was top-notch, and I had a great view of the surroundings. Will definitely visit again." },
    { name: "Josphat Kinyua", review: "The big single room is spacious and very comfortable. I loved the tranquility of the place, ideal for a relaxing night." },
    { name: "Nyams Nyams", review: "The mornings are chilly, but the warmth of the staff and the atmosphere of the room more than made up for it." },
    { name: "Mike Otieno", review: "Modern facilities and great ambiance. The free Wi-Fi was an added bonus!" },
    { name: "Sharon Achieng", review: "The room was clean and quiet, but the food took a bit longer than expected. Other than that, great experience!" },
    { name: "Vincent Kiprotich", review: "Stayed in the big single room, and it was exactly what I needed. Quiet and peaceful, with plenty of space." },
    { name: "Eunice Nduta", review: "Loved the service here! The staff were friendly and welcoming. The room was perfect for my needs." },
    { name: "Bradley Muturi", review: "A great experience with attentive staff. The room was comfy, and the peaceful environment was just what I needed." },
    { name: "Grace Mwangi", review: "The room was exactly what I was hoping for – quiet, comfortable, and the food was tasty!" },
];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentReviewIndex((prevIndex) => (prevIndex + 1) % customerReviews.length);
    }, 4000); // Change slide every 4 seconds
    return () => clearInterval(interval);
  }, [customerReviews.length]);

  const handleRoomChange = (room) => {
    setSelectedRoom(room);
  };

  return (
    <div className="rooms-page">
      {/* Hero Section */}
      <section className="room-hero">
        <h1>Our Elegant Rooms</h1>
        <p>Your perfect stay starts here with luxurious comfort and style.</p>
      </section>

      {/* Room Selection Section */}
      <section className="room-types">
        <h2>Select Your Room</h2>
        <div className="room-selector">
          {Object.keys(roomData).map((room) => (
            <button
              key={room}
              onClick={() => handleRoomChange(room)}
              className={`room-btn ${
                selectedRoom === room ? "active" : ""
              }`}
            >
              {room}
            </button>
          ))}
        </div>
        <div className="room-display">
          <div className="room-details">
            <h3>{selectedRoom}</h3>
            <p>{roomData[selectedRoom].description}</p>
            <p className="room-price">Ksh{roomData[selectedRoom].price}</p>
          </div>
          <div className="room-image-container">
            <img
              src={roomData[selectedRoom].image}
              alt={selectedRoom}
              className="room-image"
            />
          </div>
        </div>
      </section>

      {/* Perks Section */}
      <section className="perks-section">
        <h2>Why Stay With Us</h2>
        <div className="perks-list">
          {perks.map((perk, index) => (
            <div className="perk-item" key={index}>
              <span className="perk-icon">{perk.icon}</span>
              <div className="perk-content">
                <span className="perk-label">{perk.label}</span>
                <p className="perk-description">{perk.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="room-book-now-section">
        <div className="room-book-now">
          <button><Link to='/contact'>Book Now</Link></button>
        </div>
      </section>

      {/* Customer Reviews Section */}
      <section className="customer-reviews">
      <h2>Customer Reviews</h2>
      <div className="review-slider">
        <div
          className="review-card"
          style={{
            transform: `translateX(-${currentReviewIndex * 10}%)`,
          }}
        >
          {customerReviews.map((review, index) => (
            <div className="review-content" key={index}>
              <p className="review-name">{review.name}</p>
              <p className="review-text">"{review.review}"</p>
            </div>
          ))}
        </div>
      </div>
    </section>
    </div>
  );
};

export default Rooms;
