import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
  const form = useRef();  // Ref to access the form element directly
  const [statusMessage, setStatusMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setStatusMessage('Sending...');

    // Use sendForm and pass the form reference
    emailjs.sendForm(
      'service_stopover',       // Your Service ID
      'template_ugb2xcf',       // Your Template ID
      form.current,             // Pass the form reference here
      'UVWnYtHj4v_fCTy2C'       // Your User ID
    )
    .then(() => {
      setStatusMessage('Message sent successfully!');
      form.current.reset(); // Clear form fields

      setTimeout(() => {
        setStatusMessage('');
      }, 3000);
    })
    .catch(() => {
      setStatusMessage('Failed to send message. Please try again later.');
    });
  };

  return (
    <div>
      <form ref={form} onSubmit={handleSubmit} className="contact-form">
        <label>
          Name:
          <input
            type="text"
            name="from_name"
            required
          />
        </label>
        <label>
          Email:
          <input
            type="email"
            name="from_email"
            required
          />
        </label>
        <label>
          Message:
          <textarea
            name="message"
            required
          />
        </label>
        <button type="submit" className='contact-button'>Send</button>
      </form>
      {statusMessage && <p>{statusMessage}</p>}
    </div>
  );
};

export default ContactForm;

