import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/Conference.css";
import conference1 from "../images/conference-1.jpg"
import conference2 from "../images/conference-2.jpg"
import conference3 from "../images/conference-3.jpg"

const Conference = () => {
    const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

    const customerReviews = [
        { name: "Anne Wambui", review: "The boardroom setup was perfect for our team meeting. The Wi-Fi was strong, and the AV equipment worked seamlessly." },
        { name: "Elijah Kiplangat", review: "We hosted a full-day workshop here, and everything was well-organized. The catering was timely and the staff very supportive." },
        { name: "Josephine Mwikali", review: "The theater layout was exactly what we needed for our presentation. The seating was comfortable and the projector was high quality." },
        { name: "Mutua Ndolo", review: "The U-shape layout made our brainstorming session so much easier. The team loved the snacks provided during breaks!" },
        { name: "Peter Njoroge", review: "The conference room is spacious and well-lit. The sound system worked flawlessly for our virtual participants." },
        { name: "Faith Atieno", review: "Great location for hosting events. We appreciated the free parking and professional setup. Highly recommend!" },
        { name: "Laban Barasa", review: "The classroom-style layout worked perfectly for our training session. The staff even helped us rearrange it when needed." },
        { name: "Caroline Chebet", review: "The space is clean, modern, and well-equipped. The tea breaks were a hit with everyone in our meeting!" },
        { name: "Duncan Kimathi", review: "Excellent service! The conference team ensured our event ran smoothly. The room temperature was just right for long sessions." },
        { name: "Lucy Kamau", review: "We had a successful strategy meeting here. The facilities are top-notch, and the serene environment made it easy to focus." },
    ];    
    
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentReviewIndex((prevIndex) => (prevIndex + 1) % customerReviews.length);
      }, 4000); // Change slide every 4 seconds
      return () => clearInterval(interval);
    }, [customerReviews.length]);

    const navigate = useNavigate();

    const handleCardClick = () => {
      navigate("/contact");
    };

  return (
    <div className="conference-page">
      {/* Hero Section */}
      <section className="conference-hero">
        <div className="hero-content">
          <h1>Host Your Next Event in Style</h1>
          <p>
            Modern, fully-equipped spaces for meetings, workshops, and
            conferences.
          </p>
        </div>
      </section>

      {/* Layout Section */}
      <section className="conference-layout">
        <h2>Flexible Layouts for Every Occasion</h2>
        <p>Choose the perfect setup for your event.</p>
        <div className="layout-grid">
          <div className="layout-card">
            <img src={conference3} alt="Boardroom Layout" />
            <h3>Boardroom</h3>
          </div>
          <div className="layout-card">
            <img src={conference1} alt="Theater Layout" />
            <h3>Dinner</h3>
          </div>
          {/* <div className="layout-card">
            <img src="https://biblical-centre.com/wp-content/uploads/2023/12/IMG-20231211-WA0017.jpg" alt="U-Shape Layout" />
            <h3>U-Shape</h3>
          </div> */}
          <div className="layout-card">
            <img src={conference2} alt="Classroom Layout" />
            <h3>Classroom</h3>
          </div>
        </div>
      </section>

      {/* Room Highlights Section */}
      <section className="conference-highlights">
        <h2>Why Choose Our Conference Facilities?</h2>
        <div className="highlights-grid">
          <div className="highlight-card">
            <h3>High-Speed Wi-Fi</h3>
            <p>Stay connected with fast and reliable internet for your event.</p>
          </div>
          <div className="highlight-card">
            <h3>Modern AV Equipment</h3>
            <p>Access to projectors, microphones, and sound systems.</p>
          </div>
          <div className="highlight-card">
            <h3>Catering Options</h3>
            <p>Customized meal and snack plans for your attendees.</p>
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section className="conference-pricing">
      <h2>Affordable and Transparent Pricing</h2>
      <div className="pricing-options">
        <div className="pricing-card" onClick={handleCardClick}>
          <h3>Half-Day</h3>
          <p>Ksh 5,000</p>
        </div>
        <div className="pricing-card" onClick={handleCardClick}>
          <h3>Full-Day</h3>
          <p>Ksh 10,000</p>
        </div>
        <div className="pricing-card" onClick={handleCardClick}>
          <h3>Custom Packages</h3>
          <p><span>Contact us</span> for tailored plans.</p>
        </div>
      </div>
    </section>

      {/* Customer Reviews Section */}
      <section className="customer-reviews">
      <h2>Customer Reviews</h2>
      <div className="review-slider">
        <div
          className="review-card"
          style={{
            transform: `translateX(-${currentReviewIndex * 10}%)`,
          }}
        >
          {customerReviews.map((review, index) => (
            <div className="review-content" key={index}>
              <p className="review-name">{review.name}</p>
              <p className="review-text">"{review.review}"</p>
            </div>
          ))}
        </div>
      </div>
    </section>
    </div>
  );
};

export default Conference;
