import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Fade, Slide, Zoom, Bounce, JackInTheBox, Reveal } from "react-awesome-reveal";
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import "../styles/Home.css";
import food1 from "../images/food-1.jpg"
import room2 from "../images/room-2.jpg"
import camera from "../images/camera.jpg"
import conference3 from "../images/conference-3.jpg"

const Home = () => {
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

  const customerReviews = [
    { name: "adyline C", review: "This was a really cold place (for someone from Mombasa) it's lovely, the service is awesome, the food is delicious and a lot 😂 and it's pretty affordable. I would definitely recommend a stop over here. Parking: Available and free" },
    { name: "John Njenga Kihuyu", review: "One of the greatest places I have ever visited and had a clear view of the Great Rift Valley that runs through 5 countries" },
    { name: "Lawrence Ase", review: "Excellent service. Serene Quick Stopover" },
    { name: "Zipporah Karuga", review: "Very cold in the morning. The food is just ok. Stop over, take a bite n continue on with your journey" },
    { name: "Debbie", review: "Very nice American style restrooms." },
    { name: "Samuel Njoroge", review: "Good food, amazing. But it takes too long." },
    { name: "Brian Kabira", review: "The ultimate grand stopover with quality and fresh foods ready for you" },
    { name: "alice ndungu", review: "Delicious  food and great service" },
    { name: "Mwati brayo", review: "one ot the best experience ⚡⚡" },
    { name: "Harriet Gaceri", review: "The food was sumptuous." },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentReviewIndex((prevIndex) => (prevIndex + 1) % customerReviews.length);
    }, 4000); // Change slide every 3 seconds
    return () => clearInterval(interval);
  }, [customerReviews.length]);

  return (
    <div className="home-page">
      {/* Hero Section */}
      <section className="home-hero">
        <div className="home-hero-content">
          <Fade left duration={1200}>
            <h1>Welcome to Our Hotel</h1>
          </Fade>
          <Fade right duration={1200}>
            <p>
              Experience luxury like never before at our hotel, where elegance
              meets comfort. From serene rooms to exceptional dining and reliable 
              conference facilities, every moment here is crafted for your delight.
            </p>
          </Fade>
        </div>
      </section>

      {/* Restaurant Section */}
      <section className="restaurant">
        <Slide left duration={1200}>
        <div className="restaurant-content">
            <div className="image-container">
              <img
                src={food1}
                alt="Restaurant Interior"
                className="section-image"
              />
            </div>
          <div className="text-container">
            <h2>Dining Experience</h2>
            <p>
              Our in-house restaurant redefines the art of dining. Relish a 
              gastronomical journey with dishes curated by our chefs, 
              showcasing the finest ingredients and flavors. Indulge in a blend 
              of local and international cuisine, complemented by an extensive 
              selection of beverages.
            </p>
            <p>
              Whether you're enjoying a relaxed breakfast or a romantic dinner, 
              our restaurant provides an ambiance that enhances every meal. 
              Join us for an unforgettable culinary experience.
            </p>
            <Link to="/restaurant" className="home-cta-button">See Our Menu</Link>
          </div>
        </div>
        </Slide>
      </section>

      {/* Rooms Section */}
      <section className="rooms text-below">
        <div className="rooms-content">
          <Fade duration={1200}>
          <div className="image-container">
            <img
              src={room2}
              alt="Room Interior"
              className="section-image"
            />
          </div>
          </Fade>
          <Slide left duration={1200}>
          <div className="text-container">
            <h2>Comfortable and Stylish Rooms</h2>
            <p>
              Our rooms are designed with your comfort in mind. Featuring modern
              decor and plush bedding, each room is
              a sanctuary of relaxation. Wake up to stunning views and unwind
              with luxurious touches that make every stay exceptional.
            </p>
            <p>
              From cozy single rooms perfect for solo travelers to expansive
              ones for serene relaxation, we have accommodations to
              suit every need.
            </p>
            <Link to="/rooms" className="home-cta-button">View Rooms</Link>
          </div>
          </Slide>
        </div>
      </section>

      {/* Conference Section */}
      <section className="conference">
        <div className="conference-content">
          <Slide right duration={1200}>
            <div className="image-container">
              <img
                src={conference3}
                alt="Conference Room"
                className="section-image"
              />
            </div>
          </Slide>
          <Zoom duration={1200}>
          <div className="text-container">
            <h2>Host Your Event in Style</h2>
            <p>
              Our conference facilities are designed to make your events
              seamless and impactful. Whether you're hosting a corporate
              meeting, seminar, or a grand celebration, our spaces are tailored
              to suit your needs.
            </p>
            <p>
              Equipped with on demand servies and comfortable settings,we ensure your event runs smoothly. Let us
              handle the details while you focus on creating memorable moments.
            </p>
            <Link to="/conference" className="home-cta-button">Learn More</Link>
          </div>
          </Zoom>
        </div>
      </section>

      {/* Gallery Section */}
      <section className="gallery">
        <div className="gallery-content">
        <Bounce duration={1200}>
          <div className="image-container">
            <img
              src={camera}
              alt="Gallery"
              className="section-image"
            />
          </div>
        </Bounce>
        <JackInTheBox duration={1200}>
          <div className="text-container">
            <h2>Explore Our Gallery</h2>
            <p>
              Discover the beauty of our hotel through our curated gallery.
              From tastefully decorated interiors to breathtaking outdoor views,
              immerse yourself in the elegance and charm that define us.
            </p>
            <p>
              Each photo captures the spirit of hospitality and luxury, giving
              you a glimpse of the unforgettable experience that awaits.
            </p>
            <Link to="/gallery" className="home-cta-button">View Gallery</Link>
          </div>
        </JackInTheBox>
        </div>
      </section>
      {/* About Us Section */}
      <section className="about-us">
        <div className="about-us-content">
          <Slide left duration={1200}>
            <div className="text-container">
              <h2>About Us</h2>
              <p>
                We are dedicated to providing an unforgettable experience for every guest. With years
                of experience in the hospitality industry, our mission is to combine exceptional service,
                comfort, and style in a welcoming atmosphere.
              </p>
              <Link to="/about" className="home-cta-button">Learn More About Us</Link>
            </div>
          </Slide>
          <Reveal duration={1200}>
          <div className="image-container">
            <img
              src="https://st.depositphotos.com/1561359/3755/v/450/depositphotos_37559455-stock-illustration-3d-business-man-presenting-concept.jpg"
              alt="About Us"
              className="section-image"
            />
          </div>
          </Reveal>
        </div>
      </section>
      {/* Jobs Section */}
      <section className="jobs">
        <div className="jobs-content">
          <Fade left duration={1200}>
            <div className="text-container">
              <h2>Join Our Team</h2>
              <p>
                We're always looking for talented individuals to join our growing team. If you're passionate
                about hospitality and want to be part of an exciting work environment, visit our jobs page
                to learn more about current openings.
              </p>
              <Link to="/jobs" className="home-cta-button">View Job Openings</Link>
            </div>
          </Fade>
          <Fade duration={1200}>
          <div className="image-container">
            <img
              src="https://cdn-icons-png.flaticon.com/512/9236/9236766.png"
              alt="Join Our Team"
              className="section-image"
            />
          </div>
          </Fade>
        </div>
      </section>
      {/* Contact Section */}
      <section className="contact">
        <div className="contact-content">
          <Slide right duration={1200}>
            <div className="text-container">
              <h2>Get in Touch</h2>
              <p>
                We value your feedback and inquiries. Whether you need
                assistance with bookings, have special requests, or simply want
                to share your experience, our friendly team is here to help.
              </p>
              <p>
                Reach out to us through phone, email, or our online contact form. 
                We're committed to making your stay exceptional from start to finish.
              </p>
              <Link to="/contact" className="home-cta-button">Contact Us</Link>
            </div>
          </Slide>
          <Fade duration={1200}>
          <div className="image-container">
            <img
              src="https://www.shutterstock.com/image-vector/call-center-online-customer-support-600nw-2221786753.jpg"
              alt="Contact Us"
              className="section-image"
            />
          </div>
          </Fade>
        </div>
      </section>
      {/* Revews Section */}
      <section className="home-customer-reviews">
        <h2>Customer Reviews</h2>
        <div className="home-review-slider">
          <div
            className="home-review-card"
            style={{
              transform: `translateX(-${currentReviewIndex * 10}%)`,
            }}
          >
            {customerReviews.map((review, index) => (
              <div className="home-review-content" key={index}>
                <p className="home-review-name">{review.name}</p>
                <p className="home-review-text">"{review.review}"</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <div className="home navigation">
      <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/restaurant">Restaurant</Link></li>
              <li><Link to="/rooms">Rooms</Link></li>
              <li><Link to="/conference">Conference</Link></li>
              <li><Link to="/gallery">Gallery</Link></li>
              <li><Link to="/about">About Us</Link></li>
              <li><Link to='/jobs'>Jobs</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
      </div>

      {/* Social Media Links */}
      <div className="social-media">
            <h3>Follow Us:</h3>
            <div className="social-icons">
              <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                <FaFacebook size={30} />
              </a>
              <a href="https://x.com" target="_blank" rel="noopener noreferrer">
                <img
                    src="/Twitter X Rounded Icon.svg"
                    alt="Close Icon"
                    style={{ width: '50px', height: '50px', cursor: 'pointer', transition: 'filter 0.3s ease' }}
                    className="icon-image"
                    />
              </a>
              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                <FaInstagram size={30} />
              </a>
              <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                <FaLinkedin size={30} />
              </a>
            </div>
          </div>
    </div>
  );
};

export default Home;
