// src/components/pages/About.js
import React, { useEffect, useRef, useState } from 'react';
import '../styles/About.css';
import entrance from "../images/entrance.jpg"
import room from "../images/room.jpg"
import food from "../images/food.jpg"
import conference3 from "../images/conference-3.jpg"
import butler from "../images/butler.png"
import mainView from "../images/main-view.jpeg"

function About() {
    const audioRef = useRef(null);
    const [showPrompt, setShowPrompt] = useState(true);
    const [audioStarted, setAudioStarted] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (audioRef.current && audioStarted) {
                audioRef.current.play().catch(error => {
                    console.error("Error playing audio:", error);
                });
            }
        }, 1000); // 1 second delay

        return () => clearTimeout(timer); // Clean up the timer on component unmount
    }, [audioStarted]);

    const handleUserResponse = (response) => {
        if (response === 'yes') {
            setAudioStarted(true);
            if (audioRef.current) {
                audioRef.current.play().catch(error => {
                    console.error("Error playing audio:", error);
                });
            }
        }
        setShowPrompt(false);
    };

    return (
        <div className="about-container">
            {showPrompt ? (
                <div className="audio-prompt">
                    <h3>Would you like to listen to some background audio?</h3>
                    <button onClick={() => handleUserResponse('yes')}>
                        <i className="fas fa-utensils"></i> Yes
                    </button>
                    <button onClick={() => handleUserResponse('no')}>
                        <i className="fas fa-utensils"></i> No
                    </button>
                </div>
            ) : (
                <>
                    <div className="about-paragraph">
                        <div className="about-row">
                            <div className="about-image">
                                <img src={entrance} alt="GSO Hotel" />
                            </div>
                            <div className="about-text">
                                <p>
                                    Discover luxury at <strong>GSO, GRAND STOPOVER HOTEL</strong>. A modern escape that combines <em>Comfort</em>, <em>Convenience</em>, and <em>Efficiency</em>.
                                </p>
                            </div>
                        </div>

                        <div className="about-row">
                            <div className="about-image">
                                <img src={room} alt="Hotel Room" />
                            </div>
                            <div className="about-text">
                                <p>
                                    Our rooms and suites are carefully designed for your comfort, blending contemporary style with timeless sophistication.
                                </p>
                            </div>
                        </div>

                        <div className="about-row">
                            <div className="about-image">
                                <img src={food} alt="Dining Area" />
                            </div>
                            <div className="about-text">
                                <p>
                                    Enjoy delicious meals crafted by our talented chefs, offering a culinary journey from international gourmet to local favorites that go beyond your expectations.
                                </p>
                            </div>
                        </div>

                        <div className="about-row">
                            <div className="about-image">
                                <img src={conference3} alt="Conference Facilities" />
                            </div>
                            <div className="about-text">
                                <p>
                                    <em>GSO</em> is not just a place to stay; it’s a perfect spot for your business events. Our conference facilities are equipped with top-notch technology and personalized services to make your meetings successful.
                                </p>
                            </div>
                        </div>

                        <div className="about-row">
                            <div className="about-image">
                                <img src={butler} alt="Hotel Staff" />
                            </div>
                            <div className="about-text">
                                <p>
                                    Experience outstanding hospitality, where our dedicated staff is ready to meet your needs and ensure your stay is memorable.
                                </p>
                            </div>
                        </div>

                        <div className="about-row">
                            <div className="about-image">
                                <img src={mainView} alt="Hotel Booking" />
                            </div>
                            <div className="about-text">
                                <p>
                                    Book your stay at <em>GSO</em>, the place where luxury meets great service. A modern hotel offering comfort, convenience, and efficiency.
                                </p>
                            </div>
                        </div>

                        <audio ref={audioRef} src="/GSO.mp3" />
                    </div>
                </>
            )}
        </div>
    );
}

export default About;
