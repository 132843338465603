// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import ScrollToTop from "./components/ScrollToTop";
import Home from './components/Home';
import Restaurant from './components/Restaurant';
import Rooms from './components/Rooms';
import Conference from './components/Conference';
import Gallery from './components/Gallery';
import About from './components/About';
import Jobs from './components/Jobs';
import Contact from './components/Contact';
import '@fortawesome/fontawesome-free/css/all.min.css';


function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <h1>THE GRAND STOPOVER HOTEL</h1>
          <nav>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/restaurant">Restaurant</Link></li>
              <li><Link to="/rooms">Rooms</Link></li>
              <li><Link to="/conference">Conference</Link></li>
              <li><Link to="/gallery">Gallery</Link></li>
              <li><Link to="/about">About Us</Link></li>
              <li><Link to='/jobs'>Jobs</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </nav>
        </header>

        <main className="App-content">
          <ScrollToTop />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/rooms" element={<Rooms />} />
            <Route path="/restaurant" element={<Restaurant />} />
            <Route path="/conference" element={<Conference />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/about" element={<About />} />
            <Route path="/jobs" element={<Jobs />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>

        <footer className="App-footer">
          <p>&copy; 2024 The Grand Stopover Hotel. All rights reserved.</p>
        </footer>
      </div>
    </Router>
  );
}

export default App;
