import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/Restaurant.css";
import restaurant1 from "../images/restaurant-1.jpg";
import restaurant2 from "../images/restaurant-2.jpg";
import restaurant3 from "../images/restaurant-3.jpg";

const Restaurant = () => {
  const [selectedCategory, setSelectedCategory] = useState("Snacks");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

  const categories = ["Snacks", "Beverages", "Meat", "Staples", "Greens", "Salads", "Soups"];
  const menuItems = {
    Snacks: [
      { name: "Andazi", price: "40/=" },
      { name: "Chapati White", price: "60/=" },
      { name: "Chapati Brown", price: "60/=" },
      { name: "Samosa", price: "60/=" },
      { name: "Sausage", price: "60/=" },
      { name: "Plain Omelette", price: "150/=" },
      { name: "Spanish Omelette", price: "200/=" },
      { name: "Scrambled Eggs", price: "200/=" },
      { name: "Boiled Eggs", price: "150/=" },
      { name: "Toast Mayai", price: "180/=" },
      { name: "Nduma", price: "120/=" },
      { name: "Pancake", price: "200/=" },
      { name: "Ngwaci", price: "120/=" },
      { name: "Smokie", price: "60/=" },
      { name: "Chicken Pie", price: "200/=" },
      { name: "Kebab", price: "100/=" },
      { name: "Meat Pie", price: "200/=" },
      { name: "Tea Cake", price: "30/=" },
      { name: "Toast Plain", price: "50/=" },
      { name: "C.L.U.B Sandwich", price: "250/=" },
      { name: "B.L.T Sandwich", price: "250/=" },
      { name: "HAM Sandwich", price: "250/=" },
      { name: "Vegetable Sandwich", price: "250/=" },
    ],
    Beverages: [
      { name: "African Tea", price: "120/=" },
      { name: "Mixed Tea", price: "100/=" },
      { name: "Black Tea", price: "100/=" },
      { name: "Flavored Tea", price: "150/=" },
      { name: "White Coffee", price: "120/=" },
      { name: "Black Coffee", price: "100/=" },
      { name: "White Chocolate", price: "200/=" },
      { name: "Black Chocolate", price: "150/=" },
      { name: "White Coco", price: "200/=" },
      { name: "Black Coco", price: "150/=" },
    ],
    Meat: [
      { name: "Chicken Kienyeji Full", price: "2000/=" },
      { name: "Chicken Kienyeji Half", price: "1000/=" },
      { name: "Chicken Kienyeji Quarter", price: "500/=" },
      { name: "Chicken Broiler Full", price: "1300/=" },
      { name: "Chicken Broiler Half", price: "700/=" },
      { name: "Chicken Broiler Quarter", price: "400/=" },
      { name: "Kuku Bahati Full", price: "2000/=" },
      { name: "Kuku Bahati Half", price: "1000/=" },
      { name: "Mutton Fry 1KG", price: "900/=" },
      { name: "Mutton Fry 1/2KG", price: "550/=" },
      { name: "Mutton Fry 1/4KG", price: "300/=" },
      { name: "Mutton Choma 1KG", price: "850/=" },
      { name: "Mutton Choma 1/2KG", price: "400/=" },
      { name: "Beef Fry 1KG", price: "1000/=" },
      { name: "Beef Fry 1/2KG", price: "500/=" },
      { name: "Fish Tilapia", price: "850/=" },
      { name: "Fish Fillet", price: "800/=" },
    ],
    Staples: [
      { name: "Rice Plain", price: "200" },
      { name: "Pilau", price: "300" },
      { name: "Vegetable Rice", price: "300" },
      { name: "Saffron Rice", price: "300" },
      { name: "Ugali", price: "50" },
      { name: "Githeri", price: "200" },
      { name: "Githeri Special", price: "300" },
      { name: "Mukimo", price: "200" },
      { name: "Mukimo Special", price: "250" },
      { name: "Fries/Chips", price: "180" },
      { name: "Chips Quarter", price: "500" },
      { name: "Chips Masala", price: "250" },
      { name: "Sausage Special", price: "100" },
      { name: "Roast Potatoes", price: "250" },
      { name: "Saute Potatoes", price: "250" },
      { name: "Mashed Potatoes", price: "250" },
      { name: "Bhajia", price: "250" },
    ],
    Greens: [
      { name: "Spinach", price: "100" },
      { name: "Managu", price: "150" },
      { name: "Cabbage", price: "100" },
      { name: "Kales", price: "100" },
    ],
    Salads: [
      { name: "Coleslaw", price: "100" },
      { name: "Guacamole", price: "100" },
      { name: "Kachumbari", price: "100" },
      { name: "Fresh Garden salad", price: "150" },
      { name: "Potatoes Salad", price: "300" },
    ],
    Soups: [
      { name: "Tomato Soup", price: "200" },
      { name: "Onion Soup", price: "100" },
      { name: "Chicken Soup", price: "100" },
      { name: "Bone Soup", price: "100" },
    ],
  };

  const customerReviews = [
    { name: "adyline C", review: "This was a really cold place (for someone from Mombasa) it's lovely, the service is awesome, the food is delicious and a lot 😂 and it's pretty affordable. I would definitely recommend a stop over here. Parking: Available and free" },
    { name: "John Njenga Kihuyu", review: "One of the greatest places I have ever visited and had a clear view of the Great Rift Valley that runs through 5 countries" },
    { name: "Lawrence Ase", review: "Excellent service. Serene Quick Stopover" },
    { name: "Zipporah Karuga", review: "Very cold in the morning. The food is just ok. Stop over, take a bite n continue on with your journey" },
    { name: "Debbie", review: "Very nice American style restrooms." },
    { name: "Samuel Njoroge", review: "Good food, amazing. But it takes too long." },
    { name: "Brian Kabira", review: "The ultimate grand stopover with quality and fresh foods ready for you" },
    { name: "alice ndungu", review: "Delicious  food and great service" },
    { name: "Mwati brayo", review: "one ot the best experience ⚡⚡" },
    { name: "Harriet Gaceri", review: "The food was sumptuous." },
  ];

  const galleryImages = [restaurant1, restaurant2, restaurant3];

  // Carousel functionality
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === galleryImages.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Slide every 3 seconds
    return () => clearInterval(interval);
  }, [galleryImages.length]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentReviewIndex((prevIndex) => (prevIndex + 1) % customerReviews.length);
    }, 4000); // Change slide every 3 seconds
    return () => clearInterval(interval);
  }, [customerReviews.length]);

  return (
    <div className="restaurant-page">
      <header className="restaurant-hero">
        <div className="hero-content">
          <h1>Welcome to Our Restaurant</h1>
          <p>Delicious meals, cozy ambiance, and excellent service await you!</p>
        </div>
      </header>

      <section className="restaurant-photo-gallery">
        <h2>Our Ambiance</h2>
        <div className="restaurant-gallery">
          <img
            src={galleryImages[currentImageIndex]}
            alt={`Slide ${currentImageIndex + 1}`}
            className="restaurant-gallery-image"
          />
        </div>
      </section>

      <section className="menu-section">
        <h2>Our Menu</h2>
        <div className="menu-filters">
          {categories.map((category) => (
            <button
              key={category}
              onClick={() => setSelectedCategory(category)}
              className={`restaurant-btn ${
                selectedCategory === category ? "active" : ""
              }`}
            >
              {category}
            </button>
          ))}
        </div>
        <div className="menu-items">
          {menuItems[selectedCategory].map((item, index) => (
            <div className="menu-item" key={index}>
              <span className="menu-item-name">{item.name}</span>
              <span className="menu-item-price"> Ksh{item.price}</span>
            </div>
          ))}
        </div>
      </section>

      <section className="restaurant-order-now-section">
        <div className="restaurant-order-now">
          <button><Link to='/contact'>Order Now</Link></button>
        </div>
      </section>

      <section className="customer-reviews">
        <h2>Customer Reviews</h2>
        <div className="review-slider">
          <div
            className="review-card"
            style={{
              transform: `translateX(-${currentReviewIndex * 10}%)`,
            }}
          >
            {customerReviews.map((review, index) => (
              <div className="review-content" key={index}>
                <p className="review-name">{review.name}</p>
                <p className="review-text">"{review.review}"</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Restaurant;
